<template>
  <div class="container">
    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <div class="container__content">
      <h3>
        {{ $t('components.ui.pages.pop-up.wallet-delete-confirm.name') }}
      </h3>

      <p>
        {{ $t('components.ui.pages.pop-up.wallet-delete-confirm.text') }}
      </p>

      <div class="address">
        {{ _value.name }}
      </div>

      <div class="grid">
        <BlueButton
          :text="$t('components.ui.pages.pop-up.wallet-delete-confirm.cancel')"
          :disabled="loading"
          @click="$pop.down()"
        />

        <BlueButton
          class="light"
          :text="$t('components.ui.pages.pop-up.wallet-delete-confirm.delete')"
          :disabled="loading"
          @click="submit()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    ClosePopUpButton,
    BlueButton
  },
  data () {
    return {
      loading: false,
      selected: null
    }
  },
  computed: {
    _value () {
      return this.$store.state.popUp.active.value
    }
  },
  methods: {
    async submit () {
      try {
        this.loading = true

        const currencies = await this.$axios.delete(
          `/private/wallets/${this._value.id}`)
        if (currencies.status === 200) {
          this.$store.commit('WITHDRAWAL_REMOVE_ITEM', this._value.id)

          this.$pop.up({
            name: 'successfully',
            text: this.$t('components.ui.pages.pop-up.wallet-delete-confirm.successfully-text')
          })
        }
      } catch (err) {
        this.$pop.up({
          name: 'successfully',
          type: 'error',
          text: this.$t('components.ui.pages.pop-up.wallet-delete-confirm.error-text')
        })

        throw new Error(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 430px;
  padding: 10px;
  background: white;
  border-radius: 15px;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__content {
    padding: 0 10px 10px;

    h3 {
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
    }

    p {
      margin: 20px 0;
      font-size: 16px;
      line-height: 120%;
      color: #202539;
    }

    .address {
      padding: 10px;
      background: rgba(218, 40, 40, 0.15);
      border-radius: 5px;
      font-size: 16px;
      line-height: 100%;
      color: #202539;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }

    .grid {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .container__content {
      .grid {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
